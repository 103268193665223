import React from 'react';
import { Helmet } from 'react-helmet';
import { analytics } from '../utils/firebase';

const DEFAULT_TITLE = 'Pangkalan LPG 3 Kg Admin MyPertamina';

export const Suspensed = (Element, title) =>
  function suspense(props) {
    const screenName = title ? title + ' - ' + DEFAULT_TITLE : DEFAULT_TITLE;

    analytics.setCurrentScreen(screenName);

    return (
      <React.Suspense fallback={<div />}>
        <Helmet>
          <title>{screenName}</title>
        </Helmet>
        <Element {...props} />
      </React.Suspense>
    );
  };

export default {
  Error404: Suspensed(
    React.lazy(() => import('./Error404')),
    '404'
  ),
  Error403: Suspensed(
    React.lazy(() => import('./Error403')),
    '403'
  ),
  Login: Suspensed(
    React.lazy(() => import('./Login')),
    'Login'
  ),
  ForgotPassword: Suspensed(
    React.lazy(() => import('./ForgotPassword')),
    'Lupa Password'
  ),
  ResetPassword: Suspensed(
    React.lazy(() => import('./ResetPassword')),
    'Reset Password'
  ),
  ScanCodeMFA: Suspensed(
    React.lazy(() => import('./MfaScanCode')),
    'Scan Code MFA'
  ),
  Home: Suspensed(
    React.lazy(() => import('./Home')),
    'Dashboard'
  ),
  Stock: Suspensed(
    React.lazy(() => import('./Stock')),
    'Stok Produk'
  ),
  Transaksi: Suspensed(
    React.lazy(() => import('./Transaksi')),
    'Catatan Transaksi'
  ),
  TipePembayaran: Suspensed(
    React.lazy(() => import('./TipePembayaran')),
    'Tipe Pembayaran'
  ),
  SettingHarga: Suspensed(
    React.lazy(() => import('./SettingHarga')),
    'Atur Harga Produk'
  ),
  FormSettingHarga: Suspensed(
    React.lazy(() => import('./SettingHarga/Form')),
    'Atur Harga Produk'
  ),
  RegistrasiPangkalan: Suspensed(
    React.lazy(() => import('./RegistrasiMerchant')),
    'Registrasi Pangkalan'
  ),
  DetailRegistrasiMerchant: Suspensed(
    React.lazy(() => import('./RegistrasiMerchant/DetailRegistrasi')),
    'Detail Registrasi Merchant'
  ),
  VerificationRegistrationMerchant: Suspensed(
    React.lazy(() => import('./VerificationRegistrationMerchant')),
    'Verifikasi Data Merchant'
  ),
  DetailVerfication: Suspensed(
    React.lazy(() =>
      import('./VerificationRegistrationMerchant/DetailVerification')
    ),
    'Verifikasi Data Merchant'
  ),
  SettingMerchant: Suspensed(
    React.lazy(() => import('./SettingMerchant')),
    'Setting Data Pangkalan LPG 3 Kg'
  ),
  AgenHistory: Suspensed(
    React.lazy(() => import('./SettingMerchant/AgenHistory')),
    'Riwayat Agen'
  ),
  DetailSetting: Suspensed(
    React.lazy(() => import('./SettingMerchant/DetailSetting')),
    'Setting Data Pangkalan LPG 3 Kg'
  ),
  EditMerchant: Suspensed(
    React.lazy(() => import('./SettingMerchant/EditMerchant')),
    'Setting Data Pangkalan LPG 3 Kg'
  ),
  MyPertaminaActivation: Suspensed(
    React.lazy(() => import('./AktivasiMyPertamina')),
    'Aktivasi MyPertamina'
  ),
  MyPertaminaActivationDetail: Suspensed(
    React.lazy(() => import('./AktivasiMyPertamina/DetailAktivasi')),
    'Aktivasi MyPertamina'
  ),
  MyPertaminaActivationBulk: Suspensed(
    React.lazy(() => import('./AktivasiMyPertamina/AktivasiSekaligus')),
    'Aktivasi MyPertamina'
  ),
  AktivasiPencatatanManual: Suspensed(
    React.lazy(() => import('./AktivasiPencatatanManual')),
    'Aktivasi Pencatatan Manual'
  ),
  UpdateStockMerchant: Suspensed(
    React.lazy(() => import('./UpdateStockMerchant')),
    'Update Stok Pangkalan'
  ),
  UploadStockMerchant: Suspensed(
    React.lazy(() => import('./UpdateStockMerchant/UploadStockMerchant')),
    'Unggah Data Stok Pangkalan'
  ),
  RegistrasiSekaligusMerchant: Suspensed(
    React.lazy(() => import('./RegistrasiMerchant/RegistrasiSekaligus')),
    'Registrasi Sekaligus'
  ),
  SinkronisasiMerchant: Suspensed(
    React.lazy(() => import('./SinkronisasiMerchant')),
    'Sinkronisasi Merchant'
  ),
  DetailSinkronisasiMerchant: Suspensed(
    React.lazy(() => import('./SinkronisasiMerchant/DetailMerchant')),
    'Sinkronisasi Merchant'
  ),
  SettingHargaSekaligus: Suspensed(
    React.lazy(() => import('./SettingHarga/SettingSekaligus')),
    'Setting Sekaligus'
  ),
  faq: Suspensed(
    React.lazy(() => import('./Faq')),
    'Pengaturan FAQ'
  ),
  createFaq: Suspensed(
    React.lazy(() => import('./Faq/Form')),
    'Buat FAQ'
  ),
  updateFaq: Suspensed(
    React.lazy(() => import('./Faq/FormEdit')),
    'Edit FAQ'
  ),
  user: Suspensed(
    React.lazy(() => import('./User')),
    'User'
  ),
  permission: Suspensed(
    React.lazy(() => import('./Permission')),
    'Permission'
  ),
  role: Suspensed(
    React.lazy(() => import('./Role')),
    'Role'
  ),
  auditLog: Suspensed(
    React.lazy(() => import('./AuditLog')),
    'Audit Log'
  ),
  TermCondition: Suspensed(
    React.lazy(() => import('./TermCondition')),
    'Pengaturan S&K Pelanggan'
  ),
  TermConditionUpdate: Suspensed(
    React.lazy(() => import('./TermCondition/Update')),
    'Pengaturan S&K Pelanggan'
  ),
  Notification: Suspensed(
    React.lazy(() => import('./Notification')),
    'Notifikasi Login'
  ),
  AddNotification: Suspensed(
    React.lazy(() => import('./Notification/AddNotification')),
    'Tambah Notifikasi Login'
  ),
  EditNotification: Suspensed(
    React.lazy(() => import('./Notification/EditNotification')),
    'Ubah Notifikasi Login'
  ),
  firstLogin: Suspensed(
    React.lazy(() => import('./FirstLogin')),
    'Authentication'
  ),
  mfaTotp: Suspensed(
    React.lazy(() => import('./MfaTotp')),
    'MFA TOTP'
  ),
};
