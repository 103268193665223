import React from 'react';
import { Burger, MediaQuery, Navbar } from '@mantine/core';
import styles from './styles.scoped.css';
import { analytics } from '../../../../utils/firebase';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import LogoMap from '../../../icons/logo';
import PropTypes from 'prop-types';

function NavbarLayout({ opened, navs, theme, setOpened }) {
  return (
    <Navbar className={styles.aside} hidden={!opened} hiddenBreakpoint="sm" p="md" width={{ sm: 300, lg: 300 }}>
      <div className={styles.navActionWrapper}>
        <div className={styles.logo}>
          <MediaQuery largerThan="sm" styles={{ display: 'none !important' }}>
            <Burger
              color={theme?.colors?.dark[6]}
              mr="xl"
              onClick={() => setOpened((o) => !o)}
              opened={opened}
              size="md"
            />
          </MediaQuery>
          <LogoMap className={styles.logoMap} />
          <div>
            <p>DASHBOARD CONSOLE</p>
            <h5>SUBSIDI TEPAT</h5>
          </div>
        </div>
        {navs.map((n, idx) => {
          let child = [];
          const linkProps = {
            onClick: () => {
              if (n.to && !n.child) {
                analytics.logEvent('click_menu', {
                  target: n.to,
                  name: n.name,
                });
                setOpened(false);
              } else {
                analytics.logEvent('click_submenu', {
                  target: n.to,
                  name: n.name,
                });
              }
            },
          };
          if (n.child) {
            const linkChild = n.child.map((i, idy) => (
              <div key={`${idx}${idy}`} onClick={() => setOpened(false)}>
                <Link
                  className={[
                    styles['link-child'],
                    (location.pathname.includes(i.to) ||
                      location.pathname === i.sub) &&
                      styles.active,
                  ]
                    .filter(Boolean)
                    .join(' ')}
                  to={i.to}
                >
                  {i.icon}
                  {i.name}
                </Link>
              </div>
            ));
            child = [...child, linkChild];
            linkProps.onClick = () => {
              n.show = !n.show;
            };
          }
          return (
            <React.Fragment key={idx}>
              <Link
                className={
                  (location.pathname !== '/'
                    ? location.pathname.includes(n.to) && n.to !== '/'
                    : location.pathname === n.to) ||
                    location.pathname === n.sub
                    ? styles.active
                    : styles['link-parent']
                }
                {...linkProps}
                r={n.to}
                to={n.to && !n.child ? n.to : '#'}
              >
                {n.icon}
                {n.name}
                {n.child && (
                  <img
                    alt="Show More"
                    className={styles.arrow}
                    src={n.show ? 'assets/ic-up.svg' : 'assets/ic-down.svg'}
                  />
                )}
              </Link>
              {n.show && <div>{child}</div>}
            </React.Fragment>
          );
        })}
      </div>
      <div className={styles.helpdesk}>
          Butuh bantuan?
        <br />
          Hubungi kami melalui email
        <br />
        <a href="mailto:helpdesk135@pertamina.com">
            helpdesk135@pertamina.com
        </a>
      </div>
    </Navbar>
  );
}

export default NavbarLayout;

NavbarLayout.defaultProps = {
  navs: [],
  opened: false,
  setOpened: () => {},
  theme: {},
};

NavbarLayout.propTypes = {
  navs: PropTypes.array,
  opened: PropTypes.bool,
  setOpened: PropTypes.func,
  theme: PropTypes.object,
};
