/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */
import { AppShell, useMantineTheme } from '@mantine/core';
import React, { useContext, useEffect, useState } from 'react';
import NavbarLayout from '../Navbar/Navbar';
import HeaderLayout from '../Header/Header';
import IconFailed from '../../../icons/IconFailed';
import IconSuccess from '../../../icons/IconSuccess';
import Banner from '../../../elements/Banner';
import styles from './styles.scoped.css';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import IconRegistrasiPangkalan from '../../../icons/IconRegistrasiPangkalan';
import IconAturHET from '../../../icons/IconAturHET';
import IconSettingFaq from '../../../icons/IconSettingFaq';
import { getUserData } from '../../../../utils/storage';
import { fetchDetailUser } from '../actions';
import { router } from '../../../../utils/routeBuilder';
import { dynamicSort } from '../../../../utils/validation';
import IconManagementMerchant from '../../../icons/IconManagementMerchant';
import IconManagementUser from '../../../icons/IconManagementUser';
import IconSettingMap from '../../../icons/IconSettingMap';
import { AlertContext, AppContext, ModalContext, ToastrContext } from '../../../../contexts';
import { analytics } from '../../../../utils/firebase';
import { logout } from '../../../../pages/Login/actions';
import ModalLogout from '../ModalLogout';
import PropTypes from 'prop-types';

function Base({ children }) {
  const { breadcrumbs } = useContext(AppContext);
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);

  const { content, setContent } = useContext(ModalContext);
  const { alert, setAlert } = useContext(AlertContext);
  const { toastr, setToastr } = useContext(ToastrContext);
  const { setModalCtx } = useContext(ModalContext);

  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { dataProductCategories, dataDetailUser } = useSelector(
    (s) => s.pageBase
  );

  const noAuthRoutes = ['/login', '/forgot-password', '/reset-password', '/404', '/mfa/scan-qr', '/mfa/totp'];
  const noAuth = noAuthRoutes.some((r) => pathname.match(r));


  let navsData = [
    {
      name: 'Registrasi Pangkalan',
      to: '/registrasi-pangkalan',
      icon: <IconRegistrasiPangkalan />,
    },
    { name: 'Atur Harga Produk', to: '/price', icon: <IconAturHET /> },
    { name: 'Pengaturan FAQ', to: '/faq', icon: <IconSettingFaq /> },
  ];

  const [navs, setNavs] = useState(navsData);
  const [modal, setModal] = useState(false);

  const bcs = [...breadcrumbs];

  const {
    dataSubmitFaq,
    dataDeleteFaq,
    dataSubmitUpdateFaq,
    isLoadingSubmitFaq,
    isLoadingSubmitUpdateFaq,
    isLoadingDeleteFaq,
  } = useSelector((s) => s.faq);
  const {
    dataSubmitPermission,
    dataSubmitUpdatePermission,
    isLoadingSubmitPermission,
    isLoadingSubmitUpdatePermission,
    messageSubmitPermission,
    messageSubmitUpdatePermission,
  } = useSelector((s) => s.permission);

  const {
    dataSubmitRole,
    dataSubmitUpdateRole,
    isLoadingSubmitRole,
    isLoadingSubmitUpdateRole,
    messageSubmitRole,
    messageSubmitUpdateRole,
  } = useSelector((s) => s.role);

  const {
    deleteNotification
  } = useSelector((s) => s.notification);

  const { isLoadingUpdateTermCondition, dataUpdateTermCondition } = useSelector(
    (s) => s.termCondition
  );

  const { isLoadingSubmitAutoApprove, dataSubmitAutoApprove } = useSelector(
    (s) => s.manualLoggingActivation
  );

  const {
    isLoadingSyncUpdateStock,
    isLoadingUpdateStock,
    isLoadingUploadStock,
    messageSyncUpdateStock,
    messageUpdateStock,
    messageUploadStock,
    dataSyncUpdateStock,
    dataUploadStock
  } = useSelector((s) => s.updateStockMerchant);

  const { isOverLimitRows } = useSelector((s) => s.merchantVerification);
  const {
    suspend,
    edit: editMerchant,
    resend,
    deactivate,
    bank: bankSetting,
    sync
  } = useSelector((state) => state.merchantSetting);
  const { download, activation } = useSelector(
    (state) => state.myptmActivation
  );

  useEffect(() => {
    if(!isLoadingUpdateStock && messageUpdateStock){
      setToastr({
        desc: messageUpdateStock,
        type: 'danger',
        visible: true,
      });
    }
  }, [isLoadingUpdateStock, messageUpdateStock]);

  useEffect(() => {
    if(!isLoadingSyncUpdateStock && dataSyncUpdateStock){
      setToastr({
        desc: dataSyncUpdateStock?.message,
        type: dataSyncUpdateStock?.success ? 'success' : 'danger',
        visible: true,
      });
    }
  },[isLoadingSyncUpdateStock, dataSyncUpdateStock]);

  useEffect(() => {
    if(!isLoadingSyncUpdateStock && messageSyncUpdateStock){
      setToastr({
        desc: messageSyncUpdateStock,
        type: 'danger',
        visible: true,
      });
    }
  },[isLoadingSyncUpdateStock, messageSyncUpdateStock]);

  useEffect(() => {
    if(!isLoadingUploadStock && messageUploadStock){
      if(!messageUploadStock.includes('required')) {
        setToastr({
          desc: messageUploadStock,
          type: 'danger',
          visible: true,
        });
      }
    }
  },[isLoadingUploadStock, messageUploadStock]);

  useEffect(() => {
    if(!isLoadingUploadStock && dataUploadStock?.success){
      setToastr({
        desc: 'Berhasil mengunggah file update stok pangkalan',
        type: 'success',
        visible: true,
      });
    }
  }, [isLoadingUploadStock, messageUploadStock, dataUploadStock]);

  useEffect(() => {
    if (dataSubmitFaq && !isLoadingSubmitFaq) {
      setToastr({
        desc: 'FAQ Berhasil ditambah',
        type: 'success',
        visible: true,
      });
    }
  }, [dataSubmitFaq, isLoadingSubmitFaq]);

  useEffect(() => {
    if (deleteNotification?.success && !deleteNotification?.loading) {
      setToastr({
        desc: 'Notifikasi Berhasil dihapus',
        type: 'success',
        visible: true,
      });
    }else if(!deleteNotification?.success && !deleteNotification?.loading
        && deleteNotification?.message) {
      setToastr({
        desc:  deleteNotification?.message,
        type: 'danger',
        visible: true,
      });
    }
  }, [deleteNotification]);

  useEffect(() => {
    if (dataSubmitUpdateFaq && !isLoadingSubmitUpdateFaq) {
      setToastr({
        desc: 'FAQ Berhasil diubah',
        type: 'success',
        visible: true,
      });
    }
  }, [dataSubmitUpdateFaq, isLoadingSubmitUpdateFaq]);

  useEffect(() => {
    if (dataUpdateTermCondition && !isLoadingUpdateTermCondition) {
      setToastr({
        desc: 'Syarat & Ketentuan Berhasil diubah',
        type: 'success',
        visible: true,
      });
    }
  }, [dataUpdateTermCondition, isLoadingUpdateTermCondition]);

  useEffect(() => {
    if (dataDeleteFaq && !isLoadingDeleteFaq) {
      setToastr({
        desc: 'FAQ Berhasil dihapus',
        type: 'success',
        visible: true,
      });
    }
  }, [dataDeleteFaq, isLoadingDeleteFaq]);

  useEffect(() => {
    if (dataSubmitPermission && !isLoadingSubmitPermission) {
      setToastr({
        desc: <>
          <span className={styles.toastrMessage}>
          Berhasil menambahkan Permission{' '}
          </span>{' '}
          <b>{messageSubmitPermission}</b>
        </>,
        type: 'success',
        visible: true,
      });
    }
  }, [dataSubmitPermission, isLoadingSubmitPermission]);

  useEffect(() => {
    if (dataSubmitUpdatePermission && !isLoadingSubmitUpdatePermission) {
      setToastr({
        desc: <>
          <span className={styles.toastrMessage}>
          Berhasil mengubah Permission{' '}
          </span>{' '}
          <b>{messageSubmitUpdatePermission}</b>
        </>,
        type: 'success',
        visible: true,
      });
    }
  }, [dataSubmitUpdatePermission, isLoadingSubmitUpdatePermission]);

  useEffect(() => {
    if (dataSubmitRole && !isLoadingSubmitRole) {
      setToastr({
        desc: <>
          <span className={styles.toastrMessage}>
          Berhasil menambahkan Role{' '}
          </span>{' '}
          <b>{messageSubmitRole}</b>
        </>,
        type: 'success',
        visible: true,
      });
    }
  }, [dataSubmitRole, isLoadingSubmitRole]);

  useEffect(() => {
    if (dataSubmitUpdateRole && !isLoadingSubmitUpdateRole) {
      setToastr({
        desc: <>
          <span className={styles.toastrMessage}>
          Berhasil mengubah Role{' '}
          </span>{' '}
          <b>{messageSubmitUpdateRole}</b>
        </>,
        type: 'success',
        visible: true,
      });
    }
  }, [dataSubmitUpdateRole, isLoadingSubmitUpdateRole]);

  useEffect(() => {
    if(dataSubmitAutoApprove && !isLoadingSubmitAutoApprove){
      setToastr({
        desc: dataSubmitAutoApprove === 'all' ?
          <>
            <span className={styles.toastrMessage}>
          Berhasil mengubah pengaturan sekaligus
            </span>
          </> :
          <>
            <span className={styles.toastrMessage}>
        Berhasil mengubah pengaturan untuk Kelurahan
            </span> {' '}
            <b>{dataSubmitAutoApprove}</b>
          </>,
        type: 'success',
        visible: true,
      });
    }
  },[isLoadingSubmitAutoApprove, dataSubmitAutoApprove]);

  useEffect(() => {
    if (
      !dataSubmitPermission &&
      messageSubmitPermission &&
      !isLoadingSubmitPermission
    ) {
      setToastr({
        desc: messageSubmitPermission,
        type: 'danger',
        visible: true,
      });
    }
  }, [isLoadingSubmitPermission]);

  useEffect(() => {
    if (!dataSubmitRole && messageSubmitRole && !isLoadingSubmitRole) {
      setToastr({
        desc: messageSubmitRole,
        type: 'danger',
        visible: true,
      });
    }
  }, [isLoadingSubmitRole]);

  useEffect(() => {
    if (isOverLimitRows.showAlert && isOverLimitRows.rowsSelected > 20) {
      setToastr({
        desc: <>
          <span className={styles.dangerToastr}>
          Batas maksimal yang dapat dipilih hanya <b>20</b> pangkalan LPG 3 kg
          </span>
        </>,
        type: 'danger',
        visible: true,
      });
    }
  }, [isOverLimitRows]);

  useEffect(() => {
    if (suspend.message) {
      setToastr({
        desc: suspend.message,
        type: suspend.success ? 'success' : 'danger',
        visible: true,
      });
    }
  }, [suspend.success, suspend.message]);

  useEffect(() => {
    if (resend.message) {
      setToastr({
        desc: resend.message,
        type: resend.success ? 'success' : 'danger',
        visible: true,
      });
    }
  }, [resend.success, resend.message]);

  useEffect(() => {
    if (bankSetting.message) {
      setToastr({
        desc: bankSetting.message,
        type: bankSetting.success ? 'success' : 'danger',
        visible: true,
      });
    }
  }, [bankSetting.success, bankSetting.message]);

  useEffect(() => {
    if (editMerchant.message) {
      setToastr({
        desc: editMerchant.message,
        type: editMerchant.success ? 'success' : 'danger',
        visible: true,
      });
    }
  }, [editMerchant.success, editMerchant.message]);

  useEffect(() => {
    if (deactivate.message) {
      setToastr({
        desc: deactivate.message,
        type: deactivate.success ? 'success' : 'danger',
        visible: true,
      });
    }
  }, [deactivate.success, deactivate.message]);

  useEffect(() => {
    if(!sync.loading && sync.success){
      setToastr({
        visible: true,
        desc: 'Berhasil sinkronisasi data dengan Monica',
        type: 'success',
      });
    }
    if(!sync.loading && !sync.success && sync.message && !sync.message.includes('Terjadi kesalahan')){
      setModalCtx({ name: 'sync', opened: true, data: sync.data });
    }

    if(!sync.loading && !sync.success && sync.message.includes('Terjadi kesalahan')){
      setToastr({
        visible: true,
        desc: sync.message,
        type: 'danger',
      });
    }
  },[sync]);

  useEffect(() => {
    if (download.message) {
      setToastr({
        desc: download.message,
        type: download.toastrType,
        visible: true,
      });
    }
  }, [download.success, download.message, download.toastrType]);

  useEffect(() => {
    if (activation.message) {
      setToastr({
        desc: activation.message,
        type: activation.toastrType,
        visible: true,
      });
    }
  }, [activation.success, activation.message, activation.toastrType]);

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    body.style.backgroundColor = noAuthRoutes.includes(pathname)
      ? '#FFFFFF'
      : '#F5F6FA';
  }, [pathname]);

  useEffect(() => {
    if (!noAuth) {
      const app = document.getElementById('app');
      app.className = 'pagebase';
    }
  }, []);

  useEffect(() => {
    if (getUserData()?.adminId) {
      dispatch(fetchDetailUser());
    }
  }, []);

  const filterItems = (arr, query) => {
    return arr?.filter((el) => el?.type?.includes(query));
  };

  useEffect(() => {
    const permission = dataDetailUser?.permitions?.map((object) => {
      const icon = router.find((route) => route.path === object.path)?.icon;
      const type = router.find((route) => route.path === object.path)?.menu;
      const serialNumber = router.find((route) => route.path === object.path)?.serialNumber;

      const menu = {
        name: object.name,
        to: object.path,
        icon: icon,
        type,
        serialNumber
      };
      return menu;
    });

    const childrenMenuManajemenMerchant = filterItems(permission, 'management-merchant');
    const childrenMenuManajemenUser = filterItems(permission, 'management-user');
    const childrenMenuSettingMap = filterItems(permission, 'setting-map');

    const manajemenMerchant =
      childrenMenuManajemenMerchant.length > 0
        ? [
          {
            name: 'Manajemen Pangkalan LPG 3 Kg',
            child: childrenMenuManajemenMerchant.sort(dynamicSort('serialNumber')),
            icon: <IconManagementMerchant />,
            show: pathname.includes('/merchant/'),
          },
        ]
        : [];

    const manajemenUser =
      childrenMenuManajemenUser.length > 0
        ? [
          {
            name: 'Manajemen User',
            child: childrenMenuManajemenUser.sort(dynamicSort('serialNumber')),
            icon: <IconManagementUser />,
            show: pathname.includes('/user-management/'),
          },
        ]
        : [];

    const settingMap =
        childrenMenuSettingMap.length > 0
          ? [
            {
              name: 'Pengaturan MAP',
              child: childrenMenuSettingMap.sort(dynamicSort('serialNumber')),
              icon: <IconSettingMap />,
              show: pathname.includes('/merchant/'),
            },
          ]
          : [];
    const navs = [
      ...filterItems(permission, 'price'),
      // ...filterItems(permission, 'faq'),
      // ...filterItems(permission, 'term'),
      ...settingMap,
      ...manajemenMerchant,
      ...manajemenUser,
    ];
    setNavs(navs);
  }, [dataProductCategories, dataDetailUser]);

  const closeModal = (e) => {
    if (e.target === e.currentTarget) {
      setContent(null);
    }
  };

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    if (content) {
      body.className = 'modal-open';
    }
    if (alert) {
      body.className = 'alert-open';
    } else {
      setAlert(null);
    }
    if (!alert && !content) {
      body.className = '';
    }
  }, [content, alert]);

  if (noAuth) {
    return (
      <>
        {children}
        {toastr?.visible ? (
          <div className={styles.alert}>
            <Banner
              {...toastr}
              icon={
                toastr.type === 'success' ? (
                  <IconSuccess className={styles.icon} />
                ) : (
                  <IconFailed />
                )
              }
            />
          </div>
        ) : null}
        <div className="modal" onClick={closeModal}>
          {content}
        </div>
      </>
    );
  }

  return (
    <AppShell
      header={<HeaderLayout
        bcs={bcs}
        dataDetailUser={dataDetailUser}
        opened={opened}
        pathname={pathname}
        setModal={setModal} setOpened={setOpened} theme={theme} toastr={toastr}/>}
      layout="alt"
      navbar={<NavbarLayout navs={navs} opened={opened} setOpened={setOpened} theme={theme}/>}
      navbarOffsetBreakpoint="sm"
      styles={{
        main: {
          background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
          overflowX: 'hidden'
        },
      }}
    >
      <div className={styles.children} id="children-container">
        {children}
      </div>
      <div className="modal" onClick={closeModal}>
        {content}
      </div>
      <ModalLogout
        onClick={() => {
          analytics.logEvent('confirm_logout', {
            from_path: location.pathname,
          });
          dispatch(logout());
        }}
        onClose={() => {
          analytics.logEvent('cancel_logout', {
            to_path: location.pathname,
          });
          setModal(false);
        }}
        open={modal}
      />
    </AppShell>
  );
}

export default Base;

Base.defaultProps = {
  children: null,
};
Base.propTypes = {
  children: PropTypes.node
};
