export const isRequired = (value, message) => (!value ? message : '');

export const isOnlyLetter = (value, message) => {
  const regex = /^[a-z A-Z]+$/;
  return !regex.test(value) ? message : '';
};

export const isOnlyNumber = (value, message) => {
  const regex = /^[0-9]+$/;
  return !regex.test(value) ? message : '';
};

export const isEmail = (value, message) => {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  return !regex.test(value) ? message : '';
};

export const isMinChar = (number, value, message) => {
  return value.length < number ? message : '';
};

export const isExactChar = (number, value, message) => {
  const length = value.length;
  return length < number || length > number ? message : '';
};

export const passwordStrength = (value) => {
  let strength = 0;

  const letter = /[a-zA-Z]/g;
  const number = /[0-9]/g;
  const symbol = /[!@#$%^&*]/g;
  const isMinimum = value.length >= 6;

  if (isMinimum) {
    strength += 1;
  }
  if (isMinimum && value.match(letter)) {
    strength += 1;
  }
  if (isMinimum && value.match(number)) {
    strength += 1;
  }
  if (isMinimum && value.match(symbol)) {
    strength += 1;
  }

  return strength;
};

export const isEqualValue = (value, actualValue, message) => {
  return value !== actualValue ? message : '';
};

export const dynamicSort = (property) => {
  let sortOrder = 1;
  if(property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (firstArray,secondArray) {
    let result;
    if (firstArray[property] < secondArray[property]) {
      result = -1;
    } else if (firstArray[property] > secondArray[property]) {
      result = 1;
    } else {
      result = 0;
    }
    return result * sortOrder;
  };
};
export function isAppleDevice() {
  const userAgent = window.navigator.userAgent || window.opera;

  // Check if the device is iOS (iPhone, iPad, or iPod)
  const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;

  // Check if the platform is macOS
  const isMac = /Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent);

  return isIOS || isMac;
}
