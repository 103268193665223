import React from 'react';
import { render } from 'react-dom';
import 'typeface-open-sans';
import configureStore, { history } from './store/configureStore';
import App from './App';
import './App.css';
import './favicon.png';
import './utils/firebase';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { MantineProvider } from '@mantine/core';

const store = configureStore();

const customHistory = createBrowserHistory({ basename: '/dc' });
const theme = {
  fontFamily: 'Poppins, Open Sans, sans-serif',
};
render(
  <Provider store={store}>
    <MantineProvider theme={theme}>
      <App history={customHistory} store={store} />
    </MantineProvider>
  </Provider>, document.getElementById('app'));

moduleHotAccept(module);

export function moduleHotAccept(mod) {
  if (mod.hot) {
    mod.hot.accept('./App', () => {
      const NewApp = require('./App').default;
      render(
        <NewApp history={history} store={store} />,
        document.getElementById('app')
      );
    });
  }
}
