// import { SUCCESS } from './constants';
import { FAILED, LOADING, SUCCESS } from './constants';

const initialState = {
  isLoading: true,
  message: '',
  data: {},
  success: false
};

export default function reducer(state = initialState, action) {
  const { type, isLoading, message } = action;

  switch (type) {
    case SUCCESS:
      return {
        ...state,
        data: action?.data,
        isLoading: false,
        success: true,
      };
    case FAILED:
      return {
        ...state,
        message,
        isLoading: action.isLoading,
        success: false,
        code: action.code
      };

    case LOADING:
      return {
        ...state,
        isLoading,
      };
    default:
      return state;
  }
}
