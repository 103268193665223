import { SUCCESS, LOADING, FAILED } from './constants';
import {
  getDetailAdmin,
  listAgents,
  listBank,
  listCities,
  listDistricts,
  listProductsCategories,
  listProvinces,
  listRegionals,
  listRoleUser,
  listSam,
  listSbm,
  listVillages,
  merchantTypes,
} from '../../../utils/api';

export function failedAction(message, key) {
  return { type: FAILED, message, key };
}

export function loadingAction(isLoading, key) {
  return { type: LOADING, isLoading, key };
}

export function successAction(data, key) {
  return { type: SUCCESS, data, key };
}

export const fetchListBanks = (search) => {
  return async (dispatch) => {
    const key = 'Banks';
    dispatch(loadingAction(true, key));
    try {
      const { data } = await listBank({ search });
      let banks = data.map(({ bankId, bankName, bankCode }) => ({
        value: bankName,
        name: bankName,
        label: bankName,
        code: bankCode,
        id: bankId,
      }));
      dispatch(successAction(banks, key));
    } catch (error) {
      dispatch(failedAction(error.message, key));
    }
  };
};

export const fetchProductCategories = () => {
  return async (dispatch) => {
    const key = 'ProductCategories';
    dispatch(loadingAction(true, key));
    try {
      const { data } = await listProductsCategories();
      dispatch(successAction(data, key));
    } catch (error) {
      dispatch(failedAction(error.message, key));
    }
  };
};

export const fetchAgen = (search, byId = false) => {
  return async (dispatch, getState) => {
    const { dataAgens } = getState().pageBase;
    if (dataAgens && dataAgens.length > 0) {
      return;
    }
    const key = 'Agents';
    dispatch(loadingAction(true, key));
    try {
      const { data } = await listAgents(search);
      let agents = data.map((c) => ({
        value: byId ? c.id : c.agenId,
        name: `${c.agenId}`,
        label: `${c.agenId} - ${c.agenName}`,
      }));
      dispatch(successAction(agents, key));
    } catch (error) {
      dispatch(failedAction(error.message, key));
    }
  };
};

export const fetchRegionals = () => {
  return async (dispatch, getState) => {
    const { dataRegionals } = getState().pageBase;
    if (dataRegionals && dataRegionals.length > 0) {
      return;
    }
    const key = 'Regionals';
    dispatch(loadingAction(true, key));
    try {
      const { data } = await listRegionals();
      let regionals = data.map((c) => ({
        value: c.regional,
        name: c.regionalName ||'Semua Regional',
        label: c.regionalName,
      }));
      dispatch(successAction(regionals, key));
    } catch (error) {
      dispatch(failedAction(error.message, key));
    }
  };
};

export const fetchProvinces = (regional) => {
  return async (dispatch) => {
    // const { dataProvinces } = getState().pageBase;
    // if (dataProvinces && dataProvinces.length > 0) {
    //   return;
    // }

    const key = 'Provinces';
    dispatch(loadingAction(true, key));
    try {
      const { data } = await listProvinces(regional);
      let provinces = data.map((c) => ({
        value: c.provinceId,
        name: c.province || 'Semua Provinsi',
        regional: c.regional,
        label: c.province
      }));
      dispatch(successAction(provinces, key));
    } catch (error) {
      dispatch(failedAction(error.message, key));
    }
  };
};

export const fetchCities = (provinceId, selectedData = []) => {
  return async (dispatch) => {
    const key = 'Cities';
    dispatch(loadingAction(true, key));
    try {
      let cities = [];
      // if (provinceId) {
      const { data } = await listCities(provinceId);
      if(!selectedData || selectedData?.length < 1){
        cities = data.map((c) => ({
          value: c.cityId,
          name: c.name || 'Semua Kota/Kabupaten',
          label: c.name,
        }));
      }else{
        cities = data.filter(i => !selectedData.includes(i.cityId)).map((c) => ({
          value: c.cityId,
          name: c.name || 'Semua Kota/Kabupaten',
          label: c.name,
        }));
      }
      dispatch(successAction([...cities, ...selectedData], key));
    } catch (error) {
      dispatch(failedAction(error.message, key));
    }
  };
};

export const fetchDistricts = (cityId = '', selectedData = []) => {
  return async (dispatch) => {
    const key = 'Districts';
    dispatch(loadingAction(true, key));
    try {
      let districts = [];
      // if (cityId) {
      const { data } = await listDistricts(cityId);
      if(!selectedData || selectedData?.length < 1){
        districts = data.map((c) => ({
          value: c.districtId,
          name: c.district_name || 'Semua Kecamatan',
          label: c.district_name,
        }));
      }else{
        districts = data.filter(i => !selectedData.includes(i.districtId)).map((c) => ({
          value: c.districtId,
          name: c.district_name || 'Semua Kecamatan',
          label: c.district_name,
        }));
      }
      dispatch(successAction([...districts, ...selectedData], key));
    } catch (error) {
      dispatch(failedAction(error.message, key));
    }
  };
};

export const fetchVillages = (params, selectedData = []) => {
  return async (dispatch) => {
    const key = 'Villages';
    dispatch(loadingAction(true, key));
    try {
      let villages = [];
      // if (districtId) {
      const { data } = await listVillages(params);
      if(!selectedData || selectedData?.length < 1){
        villages = data.map((c) => ({
          value: c.villageId,
          name: c.village_name,
          label: c.village_name,
        }));
      }else{
        villages = data.filter(i => !selectedData.includes(i.districtId))
          .map((c) => ({
            value: c.villageId,
            name: c.village_name,
            label: c.village_name,
          }));
      }
      dispatch(successAction([...villages, ...selectedData], key));
    } catch (error) {
      dispatch(failedAction(error.message, key));
    }
  };
};

export const fetchSam = (regional) => {
  return async (dispatch) => {
    const key = 'Sam';
    dispatch(loadingAction(true, key));
    try {
      let sam = [];
      // if (regional) {
      const { data } = await listSam(regional);
      sam = data.map((c) => ({
        value: c.samId,
        name: c.samName,
        label: c.samName,
      }));
      // }
      dispatch(successAction(sam, key));
    } catch (error) {
      dispatch(failedAction(error.message, key));
    }
  };
};

export const fetchSbm = (params, selectedData = []) => {
  return async (dispatch) => {
    const key = 'Sbm';
    dispatch(loadingAction(true, key));
    try {
      const { data } = await listSbm(params);
      let sbm = [];
      if(!selectedData || selectedData?.length < 1){
        sbm = data.map((c) => ({
          value: c.sbmId,
          name: c.sbmName,
          label: c.sbmName,
        }));
      }else{
        sbm = data.filter(c => !selectedData.includes(c.sbmId)).map((c) => ({
          value: c.sbmId,
          name: c.sbmName,
          label: c.sbmName,
        }));
      }
      dispatch(successAction([...sbm, ...selectedData], key));
    } catch (error) {
      dispatch(failedAction(error.message, key));
    }
  };
};

export function fetchMerchantTypes() {
  return async (dispatch) => {
    const key = 'MerchantTypes';
    try {
      dispatch(loadingAction(true, key));
      const { data } = await merchantTypes();
      let types = data.map((c) => ({
        value: c.merchantTypeId,
        name: c.name,
      }));
      dispatch(successAction(types, key));
    } catch (error) {
      dispatch(failedAction(error.message, key));
    }
  };
}

export function fetchDetailUser() {
  return async (dispatch) => {
    const key = 'DetailUser';
    try {
      dispatch(loadingAction(true, key));
      const { data } = await getDetailAdmin();

      dispatch(successAction(data, key));
    } catch (error) {
      dispatch(failedAction(error.message, key));
    }
  };
}

export const fetchRoles = () => {
  return async (dispatch, getState) => {
    const { dataRoles } = getState().pageBase;
    if (dataRoles && dataRoles.length > 0) {
      return;
    }

    const key = 'Roles';
    dispatch(loadingAction(true, key));
    try {
      const { data } = await listRoleUser({ size: 100, page: 1, search: '' });
      let roles = data.map((c) => ({
        value: c.roleId,
        name: c.name,
        label: c.name,
      }));
      dispatch(successAction(roles, key));
    } catch (error) {
      dispatch(failedAction(error.message, key));
    }
  };
};
