import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getToken } from '../../utils/storage';

function ProtectedRoutes({ children }) {
  useEffect(() => {
    if(!getToken()){
      location.replace('/dc/login');
    }});
  return (
    <>{children}</>
  );
}

export default ProtectedRoutes;

ProtectedRoutes.propTypes = {
  children: PropTypes.node,
};
ProtectedRoutes.defaultProps = {
  children: null,
};
