import { FAILED, LOADING, RESET } from './constants';

const initialState = {
  isLoading: false,
  message: [],
  countDownTime: 0,
  code: 0,
};

export default function reducer(state = initialState, action) {
  const { type, isLoading, message, countDownTime, code } = action;

  switch (type) {
    case FAILED:
      return {
        ...state,
        message,
        countDownTime,
        code,
      };

    case LOADING:
      return {
        ...state,
        isLoading,
      };

    case RESET:
      return initialState;

    default:
      return state;
  }
}
