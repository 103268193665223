import { clearStorages, setTempToken, setUserData } from '../../utils/storage';
import { loginUser, logoutUser } from '../../utils/api';
import { FAILED, LOADING } from './constants';

export function login(data) {
  return (dispatch) => {
    dispatch(loadingAction(true));

    loginUser(data)
      .then((res) => {
        dispatch(loadingAction(false));
        setTempToken(res.data.temporaryToken);
        setUserData({ isMfaActive: res.data.isMfaActive });
        if (res.data.isMfaActive) {
          location.href = '/dc/mfa/totp';
        } else {
          location.href = '/dc/mfa/scan-qr';
        }
      })
      .catch((err) => {
        const message =
          err.code >= 400 && err.code < 500
            ? err.message
            : 'Username atau password yang Anda masukan salah';
        dispatch(loginFailedAction(message));
        dispatch(loadingAction(false));
      });
  };
}

export function logout() {
  return async (dispatch) => {
    dispatch(loadingAction(true));

    try {
      await logoutUser();
    } catch ({ message }) {
      dispatch(loginFailedAction(message));
    } finally {
      clearStorages();
      location.href = '/dc/login';
    }
  };
}

function loadingAction(isLoading) {
  return { type: LOADING, isLoading };
}

function loginFailedAction(message) {
  return { type: FAILED, message };
}
